import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  FetchResult,
  fromPromise,
  InMemoryCache,
  Observable,
  split,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import { createClient } from "graphql-ws";
import { Cookies } from "react-cookie";
import { onError } from "@apollo/client/link/error";
import * as jose from "jose";

const cookies = new Cookies();

let graphqlUrl = "";
let revision = "";
if (typeof window === "undefined") {
  graphqlUrl = process.env.GRAPHQL_API as string;
  revision = process.env.REVISION as string;
} else {
  graphqlUrl = webConfig.graphqlAPI;
  revision = webConfig.revision;
}

const httpLink = createHttpLink({
  uri: graphqlUrl,
});

let endpointLink = httpLink;
if (typeof window !== "undefined") {
  const wsLink = new GraphQLWsLink(
    createClient({
      url: graphqlUrl.replaceAll("https:", "wss:"),
      connectionParams: () => {
        return refreshIfCloseToExpiration(cookies)
          .then(() => ({
            authentication: "Bearer " + cookies.get("x-ms-access-token"),
          }))
          .catch((error) => {
            // This use is probably not authenticated, continue without auth.
            return {};
          });
      },
    }),
  );

  endpointLink = split(
    ({ query }) => {
      if (typeof window === "undefined") {
        return false;
      }
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    httpLink,
  );
}

const refreshTokenLink = new ApolloLink(
  (operation, forward): Observable<FetchResult> | null => {
    if (typeof window !== "undefined") {
      return fromPromise(
        refreshIfCloseToExpiration(cookies)
          .then(() => {})
          .catch((error) => {
            // This use is probably not authenticated, continue without auth.
          }),
      ).flatMap(() => {
        return forward(operation);
      });
    }
    return forward(operation);
  },
);

const metadataHeadersLink = setContext((_, { headers }) => {
  let newHeaders = {
    ...headers,
    "x-nspr-app": "web",
    "x-nspr-app-version": revision,
  };
  return { headers: newHeaders };
});

const authLink = setContext((_, { headers }) => {
  if (typeof window === "undefined") {
    // In the backend, headers are added in the query directly, but let's add the app/revision
    return {
      headers: {
        ...headers,
      },
    };
  }
  return {
    headers: {
      ...headers,
      Authorization: "Bearer " + (cookies.get("x-ms-access-token") || ""),
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    );
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const directionalLink = new ApolloLink((operation, forward) => {
  return forward(operation);
}).split(
  (operation) => operation.getContext().skipAuth,
  metadataHeadersLink.concat(errorLink).concat(endpointLink),
  metadataHeadersLink
    .concat(errorLink)
    .concat(refreshTokenLink)
    .concat(authLink)
    .concat(endpointLink),
);
const cache: InMemoryCache = new InMemoryCache();
export const getServerClient = () => {
  return new ApolloClient({
    cache,
    ssrMode: true,
    defaultOptions: {
      query: {
        fetchPolicy: "no-cache", // It seems like this doesn't really work?
      },
    },
    link: directionalLink,
  });
};
const client = new ApolloClient({
  cache,
  ssrMode: true,
  defaultOptions: {
    query: {
      fetchPolicy: "no-cache", // It seems like this doesn't really work?
    },
  },
  link: directionalLink,
});
export default client;

function refreshToken() {
  return fetch("/api/oauth/access_token")
    .then(async (response) => {
      const isJson = response.headers
        .get("content-type")
        ?.includes("application/json");
      const data = isJson ? await response.json() : null;

      // check for error response
      if (!response.ok) {
        // get error message from body or default to response status
        const error = (data && data.message) || response.status;
        return Promise.reject(error);
      }
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

export async function refreshIfCloseToExpiration(cookies: Cookies) {
  let loggedIn = cookies.get("x-ms-logged-in");
  if (!loggedIn) {
    return;
  }
  let tokenValue = cookies.get("x-ms-access-token");
  if (!tokenValue) {
    return refreshToken();
  }
  const claims = jose.decodeJwt(tokenValue);
  if (
    claims?.exp &&
    new Date(claims.exp * 1000).getTime() > new Date().getTime() + 20000 // Token still live for 20s at least
  ) {
    return Promise.resolve();
  }
  // It has expired, let's refresh
  return refreshToken();
}
